<template>
  <div class="box">
    <div class="fix-btn">
      <el-button type="primary" @click="addQuestion" size="small">添加题目</el-button>
    </div>
    <div class="mg-tp-20 pd-bt-20"></div>
    <draggable v-model="questionList" :options="traggabelOptions" :group="{ name: 'test', pull: true, put: false }">
    <div class="question" v-for="(item,index) in questionList" :key="index">
      <div class="title">{{index+1}}、{{item.title}} 【{{item.score}}分】</div>
      <div class="option" v-for="(child,cindex) in item.options" :key="cindex">
        {{letter[cindex]}}、{{child.title}} 【{{child.score}}分】
      </div>
      <div class="opt">
        <i class="el-icon-rank" style="font-size: 20px; margin-right: 20px; color: #999"></i>
        <el-button type="primary" @click="editQuestion(index)" size="small">编辑</el-button>
        <el-popconfirm class="mg-lf-10"
            title="确认删除吗？"
            @confirm="delQuestion(index)"
        >
          <el-button slot="reference" type="danger" size="small">删除</el-button>
        </el-popconfirm>
      </div>
    </div>
    </draggable>
    <el-dialog title="编辑题目" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="题目" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入标题">
            <el-button slot="append" icon="el-icon-circle-plus" @click="addOption">新增选项</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="" :label-width="formLabelWidth" v-for="(item,index) in form.options" :key="index">
          <el-row :gutter="20">
            <el-col :span="16">
              <el-input v-model="item.title" autocomplete="off" placeholder="请输入选项名称">
                <template slot="prepend">选项{{ letter[index] }}</template>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-input v-model="item.score" autocomplete="off" placeholder="请输入选项分数">
                <el-button slot="append" icon="el-icon-error" @click="delOption(index)">删除</el-button>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveQuestion" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <div class="fix-btn2">
      <el-button type="primary" @click="saveIt" size="small">保存题目</el-button>
    </div>
  </div>
</template>
<script>

import draggable from 'vuedraggable';
export default {
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      id: 0,
      dialogFormVisible: false,
      formLabelWidth: '80px',
      questionList: [],
      questionIdx: -1,
      form: {
        id: 0,
        title: '',
        options: []
      },
      letter: ['A','B','C','D','E','F','G','H','I','J'],
      traggabelOptions: {
        // group: {
        //   name: 'bottomNav',
        //   pull: true,
        //   put: true
        // },
        sort: true,
        animation: 100
      }
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getQuestion()
    }
  },
  mounted() {
  },
  components: {
    draggable
  },
  computed: {},
  methods: {
    addQuestion() {
      this.questionIdx = -1
      this.form = {
        id: 0,
        title: '',
        score: '',
        options: [
          {id:0,title:'',score:''},
          {id:0,title:'',score:''}
        ]
      }
      this.dialogFormVisible = true
    },
    editQuestion(idx) {
      this.questionIdx = idx
      this.form = this.questionList[idx]
      this.dialogFormVisible = true
    },
    delQuestion(idx) {
      this.questionList.splice(idx,1)
    },
    saveQuestion() {
      if(this.form.title == '') {
        this.fail('标题不能为空')
        return false
      }
      if(this.form.options.length < 2) {
        this.fail('选项不能少于2个')
        return false
      }
      var isBlank = true
      var max = 0
      this.form.options = this.form.options.filter(item => {
        if(item.title == '') {
          isBlank = false
        }
        if(item.score > max) {
          max = item.score
        }
        item.score = item.score ? item.score : 0
        return item
      })
      this.form.score = max
      if(!isBlank) {
        this.fail('选项名称不能为空')
        return false
      }
      if(this.questionIdx>-1) {
        this.questionList[this.questionIdx] = this.form
      } else {
        this.questionList.push(this.form)
      }
      this.dialogFormVisible = false
    },
    addOption() {
      if(this.form.options.length>=10) {
        this.fail('选项过多')
        return false
      }
      this.form.options.push({id:0,title:'',score:''})
    },
    delOption(idx) {
      this.form.options.splice(idx,1)
    },
    getQuestion() {
      this.$api.test.testQuestionIndex({id: this.id}, res => {
        if(res.errcode==0) {
          this.questionList = res.data.questionList
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    saveIt() {
      this.$api.test.testQuestionSave({id:this.id,question: this.questionList}, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.$router.push({name: 'test_index'})
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
  width: 70%;
}
.fix-btn {
  position: fixed;
  top: 90px;
}
.fix-btn2 {
  position: fixed;
  bottom: 50px;
}
.question {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
}
.question .option {
  margin-top: 10px;
  padding-left: 20px;
}
.question .opt {
  position: absolute;
  top: 30px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 100;
}
</style>
